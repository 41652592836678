<template>
  <div class="block">
    <span class="demonstration" style="margin-right:10px">日期</span>
    <el-date-picker
      v-model="datevalue"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="datechange"
    ></el-date-picker>
  </div>
  <el-table border :data="tableData.port" style="width: 100%;margin-top:20px; max-height:85vh;overflow-Y:scroll">
    <el-table-column label="设备号">
      <span>{{ tableData.device_number }}</span>
    </el-table-column>
    <el-table-column label="设备标签">
      <span>{{ tableData.device_tags }}</span>
    </el-table-column>
    <el-table-column label="端口号" prop="port_name" width="200"></el-table-column>
    <el-table-column label="端口状态" prop="type"></el-table-column>
    <el-table-column label="使用次数" prop="device_log_count"></el-table-column>
    <el-table-column label="耗电量" prop="power_consumption"></el-table-column>
    <el-table-column label=" 添加时间" prop="created_at"></el-table-column>
    <el-table-column label="停止充电" prop="status">
      <template #default="scope">
        <el-button
          v-if="scope.row.status == 1"
          type="text"
          size="small"
          @click="stopchong(scope.row, scope.row.status2)"
        >
          暂停充电</el-button
        >
        <el-button
          v-if="scope.row.status == 1"
          type="text"
          size="small"
          @click="stopchong(scope.row, scope.row.status2)"
        >
          停止充电</el-button
        >
        <el-button v-else type="text" size="small" @click="stopchong(scope.row, scope.row.status2)">{{
          scope.row.status == 1 ? "恢复充电" : "——"
        }}</el-button>
      </template>
    </el-table-column>
    <el-table-column label="是否禁用端口">
      <template #default="scope">
        <el-button type="text" size="small" @click="switchChange(scope.row)">
          {{ scope.row.status == 2 ? "解禁" : scope.row.status == 1 ? "禁用" : "禁用" }}</el-button
        >
      </template>
    </el-table-column>
    <el-table-column label="端口二维码">
      <template #default="scoped" style="position:relative">
        <div class="boxbox">
          <img :src="scoped.row.image.path" alt class="" />
          <img src="../../../public/bag.png" alt class="imagesbag" @click="reload(scoped.row.id, scoped.$index)" />
        </div>
      </template>
    </el-table-column>
    <el-table-column label="下载二维码">
      <template #default="scoped">
        <button @click="downloadCodeImg(scoped.row.image.path)">下载二维码</button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { devicePortList, switchDevicePort, closeCharge, reChangeQrCode } from "../../request/api";
import { ElMessage } from "element-plus";
import Moment from "moment";
export default {
  data() {
    return {
      tableData: [],
      aa: 11,
      type: "",
      datevalue: [], //日期
    };
  },

  created() {
    this.portLIst();
    this.$store.commit("pagination");
  },
  methods: {
    datechange(e) {
      console.log(e);
      this.portLIst();
    },
    reload(id) {
      reChangeQrCode({
        id: id,
        type: "device_port",
      }).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: "刷新成功",
            type: "success",
          });
          this.portLIst();
        } else {
          ElMessage.error(res.msg);
        }
      });
    },

    portLIst() {
      devicePortList({
        id: this.$route.query.id,
        start_time: this.datevalue ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss") : "",
        end_time: this.datevalue ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss") : "",
      }).then((res) => {
        this.pageTotal = res.pageTotal;
        this.tableData = res.list;
        this.tableData.port.forEach((item) => {
          item.type =
            item.status == 1
              ? "充电中"
              : item.status == 2
              ? "禁用"
              : item.status == 3
              ? "故障"
              : item.status == 0
              ? "空闲"
              : "";
        });
      });
    },
    downloadCodeImg(src) {
      var a = document.createElement("a");
      a.download = name || "pic";
      a.href = src;
      a.click();
    },
    switchChange(item) {
      let form = {};
      switchDevicePort({ id: item.id }).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.msg,
            type: "success",
          });
        } else {
          ElMessage.warning({
            message: res.msg,
            type: "success",
          });
        }
        this.portLIst();
      });
    },
    stopchong(item, status) {
      closeCharge({ id: item.id, status: status }).then((res) => {
        if (res.code == 200) {
          ElMessage.success({
            message: res.msg,
            type: "success",
          });
        } else {
          ElMessage.warning({
            message: res.msg,
            type: "success",
          });
        }

        this.portLIst();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.imagesbag {
  position: absolute;
  top: 9%;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  display: none;
}

.boxbox:hover .imagesbag {
  display: block;
}

/deep/.cell {
  text-align: center;
}

img {
  width: 100px;
  height: 100px;
}
</style>
